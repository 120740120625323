import SvgColor from 'components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/amylock',
    icon: icon('ic_analytics'),
  },
  {
    title: 'customers',
    path: '/amylock/customers',
    icon: icon('ic_user'),
  },
  {
    title: 'devices',
    path: '/amylock/devices',
    icon: icon('bus'),
  },
  {
    title: 'riders',
    path: '/amylock/riders',
    icon: icon('bus'),
  },
  {
    title: 'technicians',
    path: '/amylock/technicians',
    icon: icon('settings-plus'),
  },


];

export default navConfig;
