import SvgColor from 'components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/orca',
    icon: icon('ic_analytics'),
  },
  {
    title: 'customers',
    path: '/orca/customers',
    icon: icon('ic_user'),
  },
  {
    title: 'devices',
    path: '/orca/devices',
    icon: icon('ic_analytics'),
  },
  {
    title: 'vehicles',
    path: '/orca/vehicles',
    icon: icon('bus'),
  },
  {
    title: 'agents',
    path: '/orca/agents',
    icon: icon('user'),
  },
  {
    title: 'certificates',
    path: '/orca/certificates',
    icon: icon('ic_blog'),
  },
  {
    title: 'technicians',
    path: '/orca/technicians',
    icon: icon('ic_user'),
  },
  {
    title: 'settings',
    path: '/orca/settings',
    icon: icon('settings-plus'),
  },

  // {
  //   title: 'users',
  //   path: '/orca/users',
  //   icon: icon('ic_blog'),
  // },

];

export default navConfig;
