import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import SchoolLayout from 'views/School/School';
import AdminSchoolLayout from 'views/AdminSchools/AdminSchools';
import AdminLindaLayout from 'views/AdminLinda/AdminLinda';
import AdminAmylockLayout from 'views/AdminAmylock/AdminAmylock';
import AdminOrcaLayout from 'views/AdminOrca/AdminOrca';
import AdminKebsLayout from 'views/Kebs/Kebs';
import AdminVendorLayout from 'views/Vendor/Vendor';


export const Kebsvendors = lazy(() => import('views/Kebs/vendors'));
export const KebsMessages = lazy(() => import('views/Kebs/messages'));
export const KebsStatus = lazy(() => import('views/Kebs/status'));

export const VendorDashboard = lazy(() => import('views/Vendor/dashboard'));
export const Vendorcustomers = lazy(() => import('views/Vendor/customers'));
export const VendorMessages = lazy(() => import('views/Vendor/messages'));

// import DashboardLayout from 'layouts/dashboard';
// export const IndexPage = lazy(() => import('pages/app'));
// export const BlogPage = lazy(() => import('pages/blog'));
// export const UserPage = lazy(() => import('pages/user'));
// export const ProductsPage = lazy(() => import('pages/products'));
export const Page404 = lazy(() => import('pages/page-not-found'));
export const LoginPage = lazy(() => import('views/Login'));
export const ProductPage = lazy(() => import('views/Product'));
export const KebsProductPage = lazy(() => import('views/kebsproduct'));


export const SchoolDashboard = lazy(() => import('views/School/dashboard'));
export const SchoolBilling = lazy(() => import('views/School/billing'));
export const SchoolBuses = lazy(() => import('views/School/buses'));
export const SchoolStudents = lazy(() => import('views/School/students'));
export const SchoolDrivers = lazy(() => import('views/School/drivers'));
export const SchoolNotices = lazy(() => import('views/School/notices'));
export const SchoolParents = lazy(() => import('views/School/parents'));
export const SchoolUsers = lazy(() => import('views/School/users'));
export const SchoolProfile = lazy(() => import('views/School/profile'));

export const AdminSchoolDashboard = lazy(() => import('views/AdminSchools/dashboard'));
export const AdminSchoolSchools = lazy(() => import('views/AdminSchools/schools'));
export const AdminSchoolBilling = lazy(() => import('views/AdminSchools/payments'));
export const AdminSchoolBuses = lazy(() => import('views/AdminSchools/buses'));
export const AdminSchoolDevices = lazy(() => import('views/AdminSchools/devices'));
export const AdminSchoolUsers = lazy(() => import('views/AdminSchools/users'));
export const AdminSchoolTechnicians = lazy(() => import('views/AdminSchools/technicians'));
export const AdminSchoolDrivers = lazy(() => import('views/AdminSchools/drivers'));
export const AdminSchoolPayments = lazy(() => import('views/AdminSchools/payments'));
export const AdminSchoolProfile = lazy(() => import('views/AdminSchools/profile'));

export const AdminOrcaDashboard = lazy(() => import('views/AdminOrca/dashboard'));
export const AdminOrcaSettings = lazy(() => import('views/AdminOrca/settings'));
export const AdminOrcaCertifications = lazy(() => import('views/AdminOrca/certifications'));
export const AdminOrcaBilling = lazy(() => import('views/AdminOrca/payments'));
export const AdminOrcaAgents = lazy(() => import('views/AdminOrca/agents'));
export const AdminOrcaCustomers = lazy(() => import('views/AdminOrca/customers'));
export const AdminOrcaDevices = lazy(() => import('views/AdminOrca/devices'));
export const AdminOrcaUsers = lazy(() => import('views/AdminOrca/users'));
export const AdminOrcaTechnicians = lazy(() => import('views/AdminOrca/technicians'));
export const AdminOrcaVehicles = lazy(() => import('views/AdminOrca/vehicles'));

export const AdminOrcaDrivers = lazy(() => import('views/AdminOrca/customers'));
export const AdminOrcaPayments = lazy(() => import('views/AdminOrca/payments'));
export const AdminOrcaProfile = lazy(() => import('views/AdminOrca/profile'));



export const AdminAmylockDashboard = lazy(() => import('views/AdminAmylock/dashboard'));
export const AdminAmylockSchools = lazy(() => import('views/AdminAmylock/schools'));
export const AdminAmylockBilling = lazy(() => import('views/AdminAmylock/payments'));
export const AdminAmylockBuses = lazy(() => import('views/AdminAmylock/buses'));
export const AdminAmylockDevices = lazy(() => import('views/AdminAmylock/devices'));
export const AdminAmylockUsers = lazy(() => import('views/AdminAmylock/users'));
export const AdminAmylockTechnicians = lazy(() => import('views/AdminAmylock/technicians'));
export const AdminAmylockDrivers = lazy(() => import('views/AdminAmylock/drivers'));
export const AdminAmylockPayments = lazy(() => import('views/AdminSchools/payments'));
export const AdminAmylockProfile = lazy(() => import('views/AdminAmylock/profile'));

export const AdminLindaDashboard = lazy(() => import('views/AdminLinda/dashboard'));
export const AdminLindaCustomers = lazy(() => import('views/AdminLinda/customers'));
export const AdminLindaBilling = lazy(() => import('views/AdminLinda/payments'));
export const AdminLindaDevices = lazy(() => import('views/AdminLinda/devices'));
export const AdminLindaTechnicians = lazy(() => import('views/AdminLinda/technicians'));
export const AdminLindaDrivers = lazy(() => import('views/AdminLinda/drivers'));
export const AdminLindaPayments = lazy(() => import('views/AdminLinda/payments'));
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([

    {
      element: (
        <SchoolLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </SchoolLayout> 
      ),
      children: [
        {  path: '/school', element: <SchoolDashboard />, index: true },
        { path: '/school/buses', element: <SchoolBuses /> },
        { path: '/school/students', element: <SchoolStudents /> },
        { path: '/school/parents', element: <SchoolParents /> },
        { path: '/school/billing', element: <SchoolBilling /> },
        { path: '/school/drivers', element: <SchoolDrivers /> },
        { path: '/school/notices', element: <SchoolNotices /> },
        { path: '/school/users', element: <SchoolUsers /> },
        { path: '/school/profile', element: <SchoolProfile /> },
      ],
    },
    {
      element: (
        <AdminKebsLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminKebsLayout> 
      ),
      children: [
        {  path: '/kebs', element: <Kebsvendors />, index: true },
        {  path: '/kebs/messages', element: <KebsMessages />, index: true },
        {  path: '/kebs/status', element: <KebsStatus />, index: true },
      ],
    },
    {
      element: (
        <AdminVendorLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminVendorLayout> 
      ),
      children: [ 
        {  path: '/vendor', element: <VendorDashboard />, index: true },
        {  path: '/vendor/customers', element: <Vendorcustomers />, index: true },
        {  path: '/vendor/messages', element: <VendorMessages />, index: true },
     
      ],
    },
    {
      element: (
        <AdminSchoolLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminSchoolLayout> 
      ),
      children: [
        { path: '/shule', element: <AdminSchoolDashboard />, index: true },
        { path: '/shule/schools', element: <AdminSchoolSchools /> },
        { path: '/shule/billing', element: <AdminSchoolBilling /> },
        { path: '/shule/buses', element: <AdminSchoolBuses /> },
        { path: '/shule/profile', element: <AdminSchoolProfile /> },
        { path: '/shule/users', element: <AdminSchoolUsers /> },
        { path: '/shule/devices', element: <AdminSchoolDevices /> },
        { path: '/shule/drivers', element: <AdminSchoolDrivers /> },
        { path: '/shule/technicians', element: <AdminSchoolTechnicians /> },
        { path: '/shule/payments', element: <AdminSchoolPayments /> },
      ],
    },
    {
      element: (
        <AdminOrcaLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminOrcaLayout> 
      ),
      children: [
        { path: '/orca', element: <AdminOrcaDashboard />, index: true },
        { path: '/orca/certificates', element: <AdminOrcaCertifications /> },
        { path: '/orca/billing', element: <AdminOrcaBilling /> },
        { path: '/orca/agents', element: <AdminOrcaAgents /> },
        { path: '/orca/profile', element: <AdminOrcaProfile /> }, 
        { path: '/orca/users', element: <AdminOrcaUsers /> },
        { path: '/orca/devices', element: <AdminOrcaDevices /> },
        { path: '/orca/customers', element: <AdminOrcaCustomers /> },
        { path: '/orca/technicians', element: <AdminOrcaTechnicians /> },
        { path: '/orca/vehicles', element: <AdminOrcaVehicles /> },
        { path: '/orca/settings', element: <AdminOrcaSettings /> },
      ],
    },
    {
      element: (
        <AdminAmylockLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminAmylockLayout> 
      ),
      children: [
        { path: '/amylock', element: <AdminAmylockDashboard />, index: true },
        { path: '/amylock/schools', element: <AdminAmylockSchools /> },
        { path: '/amylock/billing', element: <AdminAmylockBilling /> },
        { path: '/amylock/buses', element: <AdminAmylockBuses /> },
        { path: '/amylock/profile', element: <AdminAmylockProfile /> },
        { path: '/amylock/users', element: <AdminAmylockUsers /> },
        { path: '/amylock/devices', element: <AdminAmylockDevices /> },
        { path: '/amylock/drivers', element: <AdminAmylockDrivers /> },
        { path: '/amylock/technicians', element: <AdminAmylockTechnicians /> },
        { path: '/amylock/payments', element: <AdminAmylockPayments /> },
      ],
    },
    {
      element: (
        <AdminLindaLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminLindaLayout> 
      ),
      children: [
        { path: '/linda', element: <AdminLindaDashboard />, index: true },
        { path: '/linda/customers', element: <AdminLindaCustomers /> },
        { path: '/linda/billing', element: <AdminSchoolBilling /> },
        { path: '/linda/devices', element: <AdminLindaDevices /> },
        { path: '/linda/drivers', element: <AdminSchoolDrivers /> },
        { path: '/linda/technicians', element: <AdminSchoolTechnicians /> },
        { path: '/linda/payments', element: <AdminSchoolPayments /> },
      ],
    },
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'Products',
      element: <ProductPage />,
    },
    {
      path: 'kebsproducts',
      element: <KebsProductPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },
  ]);

  return routes;
}
