import SvgColor from 'components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/vendor',
    icon: icon('ic_analytics'),
  },
  {
    title: 'customers',
    path: '/vendor/customers',
    icon: icon('users'),
  },
  {
    title: 'messages',
    path: '/vendor/messages',
    icon: icon('bell'),
  },



];

export default navConfig;
